.lockers--map {
  width: 100%;
  grid-column: 1 / -1;
}

.lockers--container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.locker--container {
  height: 100%;
  width: 300px;
  height: 500px;
  display: grid;
  /* column locker map in 2 side panels (20px), and 2 columns for doors */
  /* row locker map in 2 top panels (20px), and 14 columns for doors (Small panel) */
  grid-template-columns: 1fr;
  grid-template-rows: 20px 1fr 20px;
}

.locker--head {
  border: solid black;
  grid-column: 1 / -1;
  grid-row: 1 / span 1;
}

.locker--body {
  grid-column: 1 / -1;
  grid-row: 2 / -2;

  display: grid;
  grid-template-columns: 20px 1fr 20px;
  grid-template-rows: 1fr;
}

.locker--leftside {
  border: solid black;
  grid-column: 1 / span 1;
}

.locker--doors {
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(14, 1fr);
  grid-auto-flow: column;
  grid-gap: 1px;
}

.locker--rightside {
  border: solid black;
  grid-column: -2 / span 1;
}

.locker--footer {
  border: solid black;
  grid-column: 1 / -1;
  grid-row: -2 / span 1;
}

.lockers--legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  float: right;
  margin-right: 40px;
}

.lockers--legend > span {
  display: flex;
  align-items: center;
}

.lockers--legend--circle-blue {
  color: rgb(0, 154, 191, 0.65);
}

.lockers--legend--circle-dark {
  color: rgb(10, 42, 59, 0.9);
}

.lockers--legend--header {
  color: rgb(10, 42, 59, 0.9);
  font-size: 1.3rem;
  padding-left: 14px;
}
