/* adheres to style guide */
/* Device Sizes:
    Desktop: 1024px
    Tablet: 768px
    Mobile: 414px
*/

@font-face {
  font-family: 'Swiss721BTBold';
  src: url('fonts/Swiss721BTBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Swiss721BTRegular';
  src: url('fonts/Swiss721BTRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Swiss721LtBTLight';
  src: url('fonts/Swiss721LtBTLight.ttf') format('truetype');
}

html,
body,
#root {
  min-height: 100vh;
  height: auto;
  min-width: 100vw;
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: 'Swiss721BTRegular';
  clear: both;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}

h1 {
  font-size: 36px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: Bold;
}

h3 {
  font-size: 20px;
  font-weight: Bold;
}

h4 {
  font-size: 18px;
  font-weight: lighter;
}

table {
  font-size: 18px;
}

td {
  font-size: 16px;
}

body {
  font-size: 16px;
}
/* tablet */
@media (max-width: 1024px) {
  h3 {
    font-size: 18px;
  }

  td {
    font-size: 14px;
  }

  body {
    font-size: 14px;
  }
}

/* Mobile */
@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 16px;
  }

  td {
    font-size: 12px;
  }

  body {
    font-size: 14px;
  }
}

/* change color of the flag dropdown for phone input to be transparent and add underline for it */
.rrui__select__button {
  background-color: transparent !important;
  border-bottom: 1px solid #cacaca !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: auto !important;
  line-height: 1.5em !important;
  padding: 10px 25px 10px 10px !important;
}

.LogModal span.select-wrap {
  position: relative;
}

.LogModal span.select-wrap:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -3px;
  border-width: 6px;
  border-style: solid;
  border-color: #666666 transparent transparent;
}

/*  REMOVE AUTOFILL */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: transparent !important;
  color: #555 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #555555 !important;
}

/* PAGE FADE TransitionGroup */
.fadeTranslate-enter {
  opacity: 0;
  transform: translate(0, -3vh);
  position: fixed;
}

.fadeTranslate-enter.fadeTranslate-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 350ms ease-in 350ms, transform 350ms ease-in-out 350ms;
}

.fadeTranslate-exit {
  opacity: 1;
  position: fixed;
  transform: translate(0, 0);
}

.fadeTranslate-exit.fadeTranslate-exit-active {
  opacity: 0;
  transform: translate(0, 3vh);
  transition: opacity 175ms ease-in, transform 175ms ease-in-out;
}

.fix-container {
  position: fixed;
  width: inherit;
  height: calc(100vh - 75px);
  overflow-y: auto;
  right: 0;
}

.SlideIn-appear {
  opacity: 0;
}
.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transition: all 0.6s ease-in;
}
.SlideIn-enter {
  opacity: 0;
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transition: all 0.2s ease-in 0.3s;
}
.SlideIn-leave {
  opacity: 1;
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: all 0.2s ease-in;
}

/* REACT MODAL STYLING */
.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  min-width: 702px;
  max-height: 95%;

  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  outline: none;
  text-align: center;
}

.LabelModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 75%;
  min-height: 50%;
  height: auto;
  max-height: 95%;
  max-width: 500px;
  border: 3px solid #21baea;
  border-radius: 3px;
  background: white;
  color: #21baea;
  overflow: hidden;
  outline: none;
  font-family: 'Gotham Narrow Light';
  text-align: center;
}

.LogModal {
  padding: 0;
}

.CourierModal {
  width: 50%;
}

.VacationDayPicker {
  width: 45%;
  min-width: 380px;
  max-width: 630px;
}

.ConfirmationModal p {
  margin: 2px;
}

.TransferModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 75%;
  min-height: 50%;
  height: auto;
  max-height: 95%;
  border: 3px solid #21baea;
  border-radius: 3px;
  background: white;
  color: #21baea;
  overflow: hidden;
  outline: none;
  font-family: 'Gotham Narrow Book';
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

@media only screen and (max-width: 600px) {
  .LogModal {
    min-width: none;
    max-width: none;
    width: 90%;
  }

  .LabelModal {
    min-width: none;
    max-width: none;
    width: 90%;
  }

  .CourierModal {
    width: 90%;
  }

  .Modal {
    width: 90%;
  }

  .TransferModal {
    width: 90%;
  }
}

/* CHECKBOX ANIMATION */

.pretty.p-smooth label:before,
.pretty.p-smooth label:after {
  transition: all 0.1s ease-in-out !important;
  border-radius: 3px !important;
}

.pretty.p-smooth.p-default input:checked + .state label:after {
  -webkit-animation: zoom 0.1s ease-in-out !important;
  animation: zoom 0.1s ease-in-out !important;
}

.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: '';
  -webkit-transform: scale(0) !important;
  -ms-transform: scale(0) !important;
  transform: scale(0) !important;
  transition: all 0.1s ease-in-out !important;
}

/* POPUP NOTIFICATION */

.notification-success {
  width: 357px;
  min-height: 53px;
  height: auto;
  border-color: #5cba8f !important;
  box-shadow: rgba(33, 186, 234, 0.9) 0px 0px 1px !important;
  border-radius: 1px !important;
  background: #5cba8f !important;
}

.notification {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 85px;
  padding: 15px;
}

.notification-message {
  width: 80% !important;
}

.notification-success h4,
.notification-message {
  width: auto;
  font-family: 'Swiss721BTRegular' !important;
  font-size: 15px !important;
  background: transparent !important;

  color: #ffffff;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  margin-top: 15px;
}

.notification-warning {
  border-color: #cb4c6f !important;
  box-shadow: rgba(203, 76, 111, 0.9) 0px 0px 1px !important;
  border-radius: 3px !important;
  background: #cb4c6f !important;
}

.notification-warning h4 {
  text-align: center;
  color: white !important;
  font-family: 'Swiss721BTRegular' !important;
  font-size: 16px !important;
}

.notification-warning span {
  background-color: white !important;
  color: #cb4c6f !important;
}

.notification-dismiss {
  top: 10px !important;
  right: 10px !important;
  font-weight: 300 !important;
  background-color: white !important;
}

.notification-success .notification-dismiss {
  color: #5cba8f !important;
}

/* Day Picker */
.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba(8, 62, 102, 0.5) !important;
  border: none;
  color: black;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
  background-color: #083e66 !important;
  color: #083e66;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rbc-toolbar {
  display: flex;
}

.rbc-toolbar > .rbc-btn-group:last-child {
  /* Month, Week, Day NavBar */
  order: -1;
  display: flex;
  width: 319.5px;
  justify-content: space-between;
}

.rbc-toolbar > .rbc-btn-group:last-child > .rbc-active {
  background-color: #3e3e58;
  color: #ffffff;
}

.rbc-toolbar > .rbc-btn-group:last-child > button {
  /* Month, Week, Day portion of navBar within Calendar.js*/
  text-transform: uppercase;
  border-radius: 2px;
  font-weight: bold;
  height: 33px;
  width: 83px;
  border-radius: 2px;
}

.rbc-toolbar > .rbc-toolbar-label {
  visibility: hidden;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) {
  order: 1;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) {
  /* Today, Next, Prev NavBar + ProgressBar */
  display: flex;
  flex-basis: 150.6875em;
  justify-content: flex-start;
  align-items: center;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) > button:nth-child(1) {
  /* Today Button, effectively not used right now*/
  display: none;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) > button:nth-child(2) {
  /* Previous Button aka '<' */
  visibility: hidden;
  order: 2;
  font-size: 0px;
  padding: 5px 0px;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) > button:nth-child(2):before {
  visibility: visible;
  content: '<';
  border-radius: 2px;
  order: 1;
  height: 19px;
  font-family: 'Swiss721 BT';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.62px;
  line-height: 19px;
  text-align: right;
  float: right;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) > button:nth-child(3) {
  /* Next Button aka '>' */
  visibility: hidden;
  order: 4;
  font-size: 0px;
  padding: 5px 0px;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) > button:nth-child(3):before {
  visibility: visible;
  content: '>';
  border-radius: 2px;
  order: -1;
  height: 19px;
  width: 105px;
  font-family: 'Swiss721 BT';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.62px;
  line-height: 19px;
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) > #calendarLabel {
  order: 3;
  text-transform: uppercase;
  align-self: center;
  justify-content: center;
  width: 300px;
  font-family: 'Avenir Medium';
}

.rbc-toolbar > .rbc-btn-group:nth-child(1) > #capacityText {
  order: 4;
  align-self: center;
  margin-left: auto;
}

/* apply brand color orange (action3) to month header and % */
#calendarLabel,
#capacityText {
  color: #c15d26;
}

.rbc-row.rbc-month-header {
  background-color: #3e3e58;
  color: #ffffff;
  font-family: 'Avenir Medium';
}

.rbc-date-cell {
  color: #083e66;
}

.rbc-label {
  visibility: hidden;
}

.rbc-time-header {
  background-color: #3e3e58;
}

.rbc-time-header-content {
  border-left: none !important;
}

.rbc-time-view .rbc-row {
  background-color: #3e3e58;
  min-height: 0px;
}

.rbc-time-view .rbc-header {
  font-family: 'Avenir Medium';
  color: #ffffff;
  /* padding-top: 30px; */
}

.rbc-row.rbc-row.rbc-month-header > .rbc-header {
  font-family: 'Avenir Medium';
  color: #ffffff;
  padding: 30px 0;
}

.rbc-time-header.rbc-overflowing[style] {
  margin-right: 0px !important;
}

.rbc-time-content {
  overflow-y: hidden !important;
}

.rbc-label.rbc-time-header-gutter {
  display: none;
}

.rbc-time-gutter.rbc-time-column {
  display: none;
}

.rbc-row.rbc-time-header-cell > .rbc-header.rbc-today {
  background-color: #3e3e58;
}

.pluralize {
  font-size: 30px;
  font-weight: bold;
}

*:focus {
  outline: none;
}

.table {
  max-height: 541px;
  min-width: 987px;
  width: auto;
  border: 1px solid #cccccc;
  font-size: 15px;
  letter-spacing: 0.68px;
  line-height: 18px;
}

.notifTable {
  max-height: 541px;
  width: auto;
  border: 1px solid #cccccc;
  font-size: 15px;
  letter-spacing: 0.68px;
  line-height: 18px;
}

.table-header {
  font-family: 'Swiss721BTRegular';
  height: 31px;
  color: #083e66;
  background-color: #e8e8e8 !important;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: underline;
  box-sizing: border-box;
  padding: 20px 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.01);
}

.table-cell {
  box-sizing: border-box;
  font-family: 'Swiss721BTRegular';
  font-weight: 300;
  font-size: 14px;
  height: 46px;
  padding: 15px 10px !important;
  color: #333333;
}

/* to allow better height and not have approve and decline buttons overlap */
.table-cell-vachold-action {
  box-sizing: border-box;
  font-family: 'Swiss721BTRegular';
  font-weight: 300;
  font-size: 14px;
  color: #333333;
}

.table-cell-approve {
  box-sizing: border-box;
  font-family: 'Swiss721BTRegular';
  font-weight: 300;
  font-size: 14px;
  height: 46px;
  padding: 15px 10px !important;
  color: #333333;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.label-column {
  text-align: center;
}

.-odd {
  border-bottom: 1px solid #eeeef2;
}

.-even {
  border-bottom: 1px solid #eeeef2;
}

.rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 #083e66 !important;
}

.rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #083e66 !important;
}

.addressList {
  width: 80%;
  margin-left: 10%;
  margin-top: -230px;
  color: #083e66;
  background-color: white;
  text-align: center;
  position: absolute;
  z-index: 9999;
  box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc;
}

.addressItem {
  color: #083e66;
}

.addressItem:hover {
  background-color: #eeeef2;
}

::placeholder {
  color: #919191;
  opacity: 0.35;
  transition: opacity 150ms ease-in-out;
}

.react-phone-number-input__row {
  display: flex;
  align-items: flex-end;
}

.react-phone-number-input__row div {
  width: auto !important;
}

.rrui__input-field {
  font-size: 16px;
  ::placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .react-phone-number-input__row div {
    width: 100%;
  }
}

.react-phone-number-input__row {
  display: flex;
  align-items: flex-end;
}

.rrui__select__arrow {
  color: #15234a !important;
  opacity: 1 !important;
}

.rrui__expandable-list {
  color: #15234a !important;
}

/* REACT TABLE */
.nounderline {
  text-decoration: none;
}

.label-column {
  text-align: center !important;
}

.ReactTable .rt-tbody .rt-td {
  text-align: left;
}

.ReactTable .-pagination {
  display: flex;
  align-items: baseline !important;
  justify-content: flex-start !important;
  padding: 5px !important;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 0 !important;
  width: 50px;
}

.ReactTable .-pagination .-center {
  flex: 0 !important;
  flex-wrap: nowrap !important;
}

.ReactTable .-pagination .-pageSizeOptions {
  position: absolute;
  right: 15px;
}

.ReactTable .-pagination .-pageSizeOptions:before {
  content: 'Show';
  color: #666666;
  font-family: 'Swiss721BTRegular';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-right: 5px;
}

.ReactTable .-pagination .-pageSizeOptions select {
  box-sizing: border-box;
  padding: 0px 15px !important;
  min-width: 50px !important;
  font-size: 14px !important;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  padding: 0px 5px !important;
  width: 50px !important;
  height: 25px !important;
  border: 1px solid #979797 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  color: #666666 !important;
}

.ReactTable .-pagination .-pageInfo {
  font-size: 0px;
}

.ReactTable .-pagination .-pageJump input {
  height: 22px;
  width: 48.22px;
  border: 1px solid #979797;
  border-radius: 2px;
}

.ReactTable .-pagination .-pageJump {
  color: #666666;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

#panel-tooltip {
  background-color: #e4e2e2 !important;
  border: 1px solid #e4e2e2 !important;
}

/* sets 3 svg icons on registration screen to lower width */
@media (max-width: 768px) {
  .landingIcons {
    width: 385px;
  }
}
