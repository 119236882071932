.panel {
  border: solid black;
}

.panel--info--header {
  color: black;
  margin: 0;
  font-size: 1.4rem;
}

#panel-tooltip {
  border-radius: 18px !important;
}
